




















































import { Component, Vue } from 'vue-property-decorator'
import { RequestPaging } from '@/utils/util'
import {
    apiLuckyDrawLists,
    apiLuckyDrawDelete,
    apiLuckyDrawEnd,
    apiLuckyDrawStart
} from '@/api/marketing/lucky_draw'
import { PageMode } from '@/utils/type'
import LuckyDrawPane from '@/components/lucky-draw/lucky-draw-pane.vue'
import DatePicker from '@/components/date-picker.vue'
import ExportData from '@/components/export-data/index.vue'
import LsPagination from '@/components/ls-pagination.vue'
import { apiDrawList } from '@/api/marketing/gift_card'
import config from '@/config'
@Component({
    components: {
        LuckyDrawPane,
        DatePicker,
        ExportData,
        LsPagination
    }
})
export default class LuckyDraw extends Vue {
    apiDrawList = apiDrawList // 传递给导出组件的api
    config = config
    // 分页查询
    pager: RequestPaging = new RequestPaging()

    // 获取列表
    getList(page?: number): void {
        page && (this.pager.page = page)
        this.pager
            .request({
                callback: apiDrawList,
                params: {}
            })
            .then((res: any) => {
                // this.extend = res?.extend
            })
            .catch(() => {})
        console.log('623173621', this.pager)
    }

    created() {
        this.getList()
    }
}
